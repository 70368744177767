<template>
  <div class="announcements">
    <h2>Binance 最新公告</h2>
    <ul v-if="announcements.length">
      <li v-for="announcement in announcements" :key="announcement.link">
        <a :href="announcement.link" target="_blank">{{
          announcement.title
        }}</a>
      </li>
    </ul>
    <p v-else>正在加载公告...</p>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import axios from "axios";

export default {
  name: "BinanceAnnouncements",
  setup() {
    const announcements = ref([]);
    let intervalId = null;

    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(
          "https://www.binance.com/bapi/composite/v1/public/cms/article/list/query?type=1&pageNo=1&pageSize=15"
        );

        if (response.data.success) {
          const newListCatalog = response.data.data.catalogs.find(
            (catalog) => catalog.catalogId === 48
          );

          if (newListCatalog && newListCatalog.articles) {
            announcements.value = newListCatalog.articles.map((article) => ({
              title: article.title,
              link: `https://www.binance.com/zh-CN/support/announcement/${article.code}`,
            }));
          } else {
            console.error("未找到新币上市目录");
          }
        } else {
          console.error("API 错误:", response.data.message);
        }
      } catch (error) {
        console.error("获取公告时出错:", error);
      }
    };

    onMounted(() => {
      fetchAnnouncements();
      intervalId = setInterval(fetchAnnouncements, 600000); // 每10分钟刷新一次
    });

    onUnmounted(() => {
      clearInterval(intervalId); // 清除定时器
    });

    return {
      announcements,
    };
  },
};
</script>

<style scoped>
.announcements {
  width: 100%; /* 设置宽度为100% */
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.announcements h2 {
  text-align: center;
  font-size: 24px; /* 增加标题字体大小 */
  margin-bottom: 15px; /* 增加标题下方间距 */
}

.announcements ul {
  list-style-type: none;
  padding: 0;
}

.announcements li {
  margin: 8px 0; /* 减少列表项间距 */
}

.announcements a {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  font-size: 16px; /* 增加链接字体大小 */
}

.announcements a:hover {
  text-decoration: underline;
}
</style>